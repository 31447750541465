export function ratingSlick(){

    if ($('.rating__slick').length > 0) {
        $('.rating__slick').slick({
            infinite: true,
            speed: 300,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: $(`.rating__slick-prev`),
            nextArrow: $(`.rating__slick-next`),
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
}

