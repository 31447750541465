export function heroSlick(){

    if ($('.hero-one__slick').length > 0) {
        $('.hero-one__slick').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 1000,
            arrows: false,
            dots: true,
            mobileFirst: true,
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
}

