var features = new Array;


for (const item of data.dataArray) {
  const lat = item.lat;
  const long = item.long; 
  const permalink = item.permalink;
  const title = item.post_title;
  const address = item.address;
  const email = item.email;
  const number = item.number;
  const care_type_title = item.care_type[0];
  const care_type_slug = item.care_type[1];
  const care_types = item.care_types;
  const care_types_slugs = item.care_types_slugs;

  console.log('care_types ', care_types);

  features.push({
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        `${long}`,
        `${lat}`
      ]
    },
    "properties": {
      "phone": `${number}`,
      "email": `${email}`,
      "address": `${address}`,
      "careProvided": care_types,
      "careProvidedSlug": care_types_slugs,
      "propertyName": `${title}`,
      "propertyLink": `${permalink}`,
      "categoryName": `${care_type_title}`,
      "categorySlug": `${care_type_slug}`
    }
  });

};


export const config = {
  style: "mapbox://styles/mapbox/light-v10",
  accessToken: "pk.eyJ1IjoiZG92ZWhhdmVuIiwiYSI6ImNrbzRiYzd3OTFoa3IycG12eWF3cnA2a3QifQ.RMAbTP2UrpxUmZS0bjr1zg",
  data: {
    "type": "FeatureCollection",
    "features": features
  },
  center: [-120.234, 47.398],
  zoom: 6,
  title: "Replace with your title",
  description: "Replace with information about your application. Ex. You can search by address to sort the list below by distance. You can also filter the list by language support options, which days a location is open, and whether they have devices to use to complete the survey by phone or online.",
  sideBarInfo: ["Location_Name", "Address", "Phone"],
  popupInfo: ["Location_Name"],
  filters: [{
      type: "dropdown",
      title: "Languages supported: ",
      columnHeader: "Languages",
      listItems: [
        "Amharic",
        "ASL",
        "Cambodian",
        "Chinese",
        "Danish",
        "English",
        "French",
        "German",
        "Greek",
        "Hindi",
        "Italian",
        "Japanese",
        "Korean",
        "Language Line Services",
        "Norwegian",
        "Oriya",
        "Portuguese",
        "Punjabi",
        "Russian",
        "Somali",
        "Spanish",
        "Swedish",
        "Tagalog",
        "Thai",
        "Tigrinya",
        "Tongan",
        "Vietnamese",
        "Ukranian",
      ],
    },
    {
      type: "checkbox",
      title: "Devices available: ",
      columnHeader: "Devices_available", // Case sensitive - must match spreadsheet entry
      listItems: ["Computer", "Wi-Fi", "Adaptive Laptops"], // Case sensitive - must match spreadsheet entry; This will take up to six inputs but is best used with a maximum of three;
    },
    {
      type: "dropdown",
      title: "Clients: ",
      columnHeader: "Clients",
      listItems: [
        "Adults",
        "Disabled",
        "Homeless",
        "Immigrants/Refugees",
        "Low Income",
        "Seniors",
        "Youth: Pre-teen",
        "Youth: Teen",
      ],
    },
  ],
};