export function newsSlick(){

    if ($('.featured-news__slick').length > 0) {
        $('.featured-news__slick').slick({
            infinite: false,
            speed: 300,
            arrows: false,
            dots: true,
            mobileFirst: true,
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: "unslick",
                }
            ]
        });
    }
}

