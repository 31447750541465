export function testimonialSlick(){

    if ($('.testimonial__slick').length > 0) {
        $('.testimonial__slick').slick({
            infinite: true,
            centerMode: true,
            speed: 300,
            mobileFirst: true,
            prevArrow: $(`.testimonial__slick-prev`),
            nextArrow: $(`.testimonial__slick-next`),
            slidesToShow: 1,
            easing: 'easeOutElastic',
            adaptiveHeight: true,
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        dots: false,
                        arrows: true,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }

                    // settings: "unslick",

                }
            ]
        });
    }
}

