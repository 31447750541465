export function homeSlick() {
    const $items = $('.homes-listing__slick-container');
    $items.each(function (index) {
        $(`.homes-listing__slick-container--${index}`).slick({
            infinite: true,
            speed: 300,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: $(`.homes-listing__slick-prev--${index}`),
            nextArrow: $(`.homes-listing__slick-next--${index}`),
          
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScoll: 2,
                        adaptiveHeight: true,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScoll: 3,
                    }
                }
            ]
        });
    });

    const $itemsS = $('.homes-listing__slick-container-single');
    $itemsS.each(function (index) {
        $(`.homes-listing__slick-container-single--${index}`).slick({
            infinite: true,
            speed: 300,
            arrows: true,
            dots: false,
            mobileFirst: true,
            prevArrow: $(`.homes-listing__slick-prev--${index}`),
            nextArrow: $(`.homes-listing__slick-next--${index}`),
            responsive: [{
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
            
                        slidesToShow: 2,
                        slidesToScoll: 2,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScoll: 3,
                    }
                }
            ]
        });
    });
}